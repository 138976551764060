const gds_list = [
  // { id: 1 , name: 'amadeus_eretail' },
  { id: 2 , name: 'amadeus' },
  { id: 3 , name: 'galileo' },
  { id: 4 , name: 'sirena' },
  { id: 5 , name: 'gabriel' },
  { id: 6 , name: 'sabre' },
  { id: 7 , name: 'charter' },
  { id: 8 , name: 'galileo_uapi' },
  { id: 9 , name: 'accel' },
  { id: 10, name: 'fake_gds' },
  { id: 11, name: 'click_avia' },
  { id: 12, name: 'fly_dubai' },
  { id: 13, name: 'sun_express' },
  { id: 14, name: 'atlas_global' },
  { id: 15, name: 'travel_fusion' },
  { id: 16, name: 'radix' },
  { id: 17, name: 'troya' },
  { id: 18, name: 'gaia' },
  { id: 19, name: 'fare_logix' },
  { id: 20, name: 'hitit' },
  { id: 21, name: 'skyup' },
  { id: 22, name: 'afkl' },
  { id: 23, name: 'central_data_storage' },
  { id: 24, name: 'sig' },
  { id: 25, name: 'kiwi' },
  { id: 26, name: 'glory' },
  { id: 27, name: 'orchestrated_driver' },
  { id: 28, name: 'aerticket' },
  { id: 29, name: 'nemo_adapter_driver' },
  { id: 30, name: 'proxy' },
  { id: 31, name: 'pobeda_adapter' },
  { id: 32, name: 'tui' },
  { id: 33, name: 'arcadialab_adapter' },
  { id: 34, name: 'sq_driver' },
  { id: 35, name: 'yanair_adapter_driver' },
  { id: 36, name: 'travelport' },
  { id: 37, name: 'aero_crs' },
  { id: 38, name: 'aerticket_api_hub' },
  { id: 39, name: 'ndc_su' },
  { id: 40, name: 'ndc_lh' },
  { id: 41, name: 'ndc_ps' },
  { id: 42, name: 'drct' },
  { id: 43, name: 'ndc_bt' },
  { id: 44, name: 'mixvel' },
  { id: 45, name: 'seats_block' },
  { id: 46, name: 'farelogix_api' },
  { id: 47, name: 'sabre_dcp' },
  { id: 48, name: 'chartershop' },
  { id: 49, name: 'himytrip' },
  { id: 50, name: 'amadeus_ndc' },
  { id: 51, name: 'ttn_vi' },
  { id: 52, name: 'fly_one' },
  { id: 53, name: 'ubio' },
  { id: 54, name: 'pkfare_api' },
  { id: 55, name: 'didatravel_api' },
  { id: 56, name: 'amadeus_lcc' },
  { id: 57, name: 'amadeus_v2' },
  { id: 58, name: 'ndc_j2' },
  { id: 60, name: 'ndc_tk' },
]

export default {
  arrayById: () => {
    return gds_list
  },

  objectById: () => {
    return gds_list.reduce((res, v) => ({ ...res, [v.id]: v.name }), {})
  },

  objectByName: () => {
    return gds_list.reduce((res, v) => ({ ...res, [v.name]: v.name }), {})
  },

  arrayByName: () => {
    return gds_list.reduce((res, v) => ([ ...res, { id: v.name, name: v.name } ]), [])
  },

}
